import { ActionContext } from "vuex";
import { responseErrorHandler } from "@/utils";
import messageRepository from "@/repository/message";

interface ChatroomState {
  chatroom: Record<string, any>;
  messages: Array<Record<string, any>>;
}

const state: ChatroomState = {
  chatroom: {},
  messages: [],

};

const chatroom = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    /**
     * Set state.chatroom with chatroom value
     * @param {ChatroomState} state
     * @param chatroom
     */
    SET_CHATROOM(state: ChatroomState, chatroom: Record<string, any>): void {
      state.chatroom = chatroom;
    },
    /**
     * Set state.messages with messages value
     * @param {ChatroomState} state
     * @param {Array<Record<string, any>>} messages
     */
    SET_MESSAGES(
      state: ChatroomState,
      messages: Array<Record<string, any>>
    ): void {
      console.log("set message")
      state.messages = messages;
    },
    /**
     * Remove message identified by messageId from state.messages
     * @param {ChatroomState} state
     * @param {string} messageId
     */
    REMOVE_MESSAGE(state: ChatroomState, messageId: number): void {
      state.messages.splice(messageId, 1);
    },
    /**
     * Add message to state.messages
     * @param {ChatroomState} state
     * @param message
     */
    ADD_MESSAGE(state: ChatroomState, message: Record<string, any>): void {
      const emoji_text = [":bras:", ":colere:", ":degout:", ":fete:", ":hand:", ":joie:", ":peur:", ":pouce_bas:", ":pouce_haut:", ":priere:", ":reflexion:", ":rire:", ":sablier:", ":salut:", ":surprise:", ":trist:"]
      const emoji_ico_prefixe = '<img class="emoji_room_img" src="/assets/chat_emojis/'
      const emoji_ico_suffixe = '">'

      message.content=message.content.replace(
        new RegExp(emoji_text.join('|'), 'gi'),
        (match:string) => {
          const index = emoji_text.indexOf(match.toLowerCase());
          const emoji_ico = `emoji_${emoji_text[index].slice(1).slice(0,-1)}.svg`;
          return `${emoji_ico_prefixe}${emoji_ico}${emoji_ico_suffixe}`;
        }
      );
      if(state.messages.length==0){
        state.messages.push(message);
      }
      else if(message.content!=state.messages[state.messages.length-1].content || message.author_id!=state.messages[state.messages.length-1].author_id){
      state.messages.push(message);}

    },
    /**
     * Set the messages of state.messages at payload.index by payload.chatroom
     * @param {ChatroomState} state
     * @param {Record<string, any>} payload
     */
    UPDATE_MESSAGE(state: ChatroomState, payload: Record<string, any>): void {
      console.log("update message")
      Object.assign(state.messages[payload.index], payload.message);
    },
  },
  actions: {
    /**
     * Fetch messages
     * @param {ActionContext<any, any>} context
     * @param chatroomId
     */
    fetchMessages: (
      context: ActionContext<any, any>,
      chatroomId: string
    ): void => {
      messageRepository
        .getMessages(context.rootState.token, chatroomId)
        .then((messages) => {
          console.log("fetchMessages")
          context.commit("SET_MESSAGES", messages);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default chatroom;

